// import { useApiClient } from "./useApiClient";
import {
  type AuthRegisterInterface,
  type AuthLoginInterface,
  type ResetPasswordCredentialsInterface,
  type EditProfileInterface,
} from "~/models/auth";

export const useAuthApi = () => {
  const { login: loginSanctum, logout: logoutSanctum } = useSanctumAuth();

  // const apiClient = useApiClient();
  const { $axios } = useNuxtApp();

  const register = (data: AuthRegisterInterface) => {
    return $axios.post("/register", data);
  };

  const login = async (data: AuthLoginInterface) => {
    return await loginSanctum(data);
  };

  const logout = async () => {
    return await logoutSanctum();
  };

  const forgotPassword = async (email: string) => {
    return await $axios.post("/forgot-password", email);
  };

  const resetPassword = async (data: ResetPasswordCredentialsInterface) => {
    return await $axios.post("/reset-password", data);
  };

  const registerOnGoogle = async (token: string) => {
    return await $axios.get(`/google/callback?token=${token}`);
  };

  const egitProfile = async (data: EditProfileInterface, id: number) => {
    return await $axios.post(`/api/users/${id}`, {
      ...data,
      _method: "PUT",
    });
  };

  return {
    register,
    login,
    logout,
    forgotPassword,
    resetPassword,
    registerOnGoogle,
    egitProfile,
  };
};
