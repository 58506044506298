import { defineStore } from "pinia";
import { useAuthApi } from "@/composables/api/useAuthApi";
import {
  type AuthRegisterInterface,
  type AuthLoginInterface,
  type ResetPasswordCredentialsInterface,
} from "~/models/auth";

export const useAuthStore = defineStore("auth-store", () => {
  const authApi = useAuthApi();
  const loading = ref(false);

  const register = async (data: AuthRegisterInterface) => {
    try {
      loading.value = true;
      await authApi.register(data);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const login = async (data: AuthLoginInterface) => {
    try {
      loading.value = true;
      await authApi.login(data);
      const isCarAvailable = localStorage.getItem("currentCar");

      if (isCarAvailable) {
        navigateTo("/request");
      }
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const logout = async () => {
    try {
      await authApi.logout();
      navigateTo("/login");
    } catch (error: any) {
      throw error;
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      loading.value = true;
      await authApi.forgotPassword(email);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const resetPassword = async (data: ResetPasswordCredentialsInterface) => {
    try {
      loading.value = true;
      await authApi.resetPassword(data);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const registerOnGoogle = async (token: string) => {
    try {
      loading.value = true;
      return await authApi.registerOnGoogle(token);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    register,
    login,
    logout,
    forgotPassword,
    resetPassword,
    registerOnGoogle,
  };
});
